import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
const Sitemap = lazy(() => import('./components/pages/fetchSitemap'));

const App = lazy(() => import('./App'));
const Home = lazy(() => import('./components/pages/Home'));
const About = lazy(() => import('./components/pages/About'));
const Postadd = lazy(() => import('./components/pages/Postadd'));
const Formone = lazy(() => import('./components/pages/Formone'));
const Formtwo = lazy(() => import('./components/pages/Formtwo'));
const Formthree = lazy(() => import('./components/pages/Formthree'));
const Category = lazy(() => import('./components/pages/Category'));
const Mypost = lazy(() => import('./components/pages/Mypost'));
const Signup = lazy(() => import('./components/pages/Signup'));
const Message = lazy(() => import('./components/pages/Message'));
const Favorites = lazy(() => import('./components/pages/Favorites'));
const ForgetPassword = lazy(() => import('./components/pages/ForgetPassword'));
const UpdatePassword = lazy(() => import('./components/pages/UpdatePassword'));
const Userprofile = lazy(() => import('./components/pages/Userprofile'));
const Editprofile = lazy(() => import('./components/pages/Editprofile'));
const Login = lazy(() => import('./components/pages/Login'));
const Otp = lazy(() => import('./components/pages/Otp'));
const Profile = lazy(() => import('./components/pages/Profile'));
const Privacy = lazy(() => import('./components/pages/Privacy'));
const Rules = lazy(() => import('./components/pages/Rules'));
const Term = lazy(() => import('./components/pages/Term'));
const Contactus = lazy(() => import('./components/pages/Contactus'));
const Add = lazy(() => import('./components/pages/Add'));

const PageNotFound = lazy(() => import('./components/pages/PageNotFound'));


const root = ReactDOM.createRoot(
    document.getElementById("root")
);
root.render(
    <BrowserRouter>
        <Suspense >
            <Routes>
                <Route path="/" element={<App />}>
                    <Route index path="/" element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/:category' element={<Category />} />
                    <Route path='/sign-up' element={<Signup />} />
                    <Route path='/Contact-us' element={<Contactus />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/otp' element={<Otp />} />
                    <Route path='/profile/:city/:slug' element={<Profile />} />
                    <Route path='/add' element={<Add />} />
                    {/* <Route path='/sitemap.xml' element={<Sitemap />} /> */}
                    <Route path='/form-one' element={<Formone />} />
                    <Route path='/form-two' element={<Formtwo />} />
                    <Route path='/form-three' element={<Formthree />} />
                    <Route path='/post' element={<Postadd />} />
                    <Route path='/my-favorites' element={<Favorites />} />
                    <Route path='/my-message' element={<Message />} />
                    <Route path='/edit-profile' element={<Editprofile />} />
                    <Route path='/mypost' element={<Mypost />} />
                    <Route path='/user-profile' element={<Userprofile />} />

                    <Route path='/forgetpassword' element={<ForgetPassword />} />
                    <Route path='/updatepassword/:id' element={<UpdatePassword />} />

                    <Route path='/privacy-policy' element={<Privacy />} />
                    <Route path='/rules' element={<Rules />} />
                    <Route path='/term-of-services' element={<Term />} />

                    <Route path='*' element={<PageNotFound />} />
                </Route>
            </Routes>
        </Suspense>
    </BrowserRouter>
);

